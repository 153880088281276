<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Contact us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Customer Service</h2>
          <p>Get in touch with our customer service team</p>

          <ul>
            <li><a href="https://info.intrafish.com/ifco-customer-service-form">Contact us</a></li>
            <li>
              <div>Call Us:</div>
              <div class="pl-4">London: +44 207 645 2300 ,<br> Oslo: +47 2200 1200 ,<br> Stamford: +1 203 324 2994 ,<br> Singapore: +65 3165 0940 ,<br> Bergen: +47 55 21 33 00</div>
            </li>
          </ul>

          <h2 class="mt-0">IntraFish offices:</h2>

          <p>
            Please click on the office header for local staff contact details.
          </p>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Bergen
            </a>
            <br>
            <p>
              Sandbrogaten 5-7<br>
              5003 Bergen<br>
              Norway
            </p>
            <p>
              Tel: +47 55 21 33 00
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:joar.grindheim@intrafish.com">
                      Joar Grindheim
                    </a>
                  </td>
                  <td>Editor in Chief, IntraFish.no</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:Vegard.Solsletten@intrafish.no">
                      Vegard Solsletten
                    </a>
                  </td>
                  <td>Journalist, IntraFish.no</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:Lena.jensen@fiskeribladet.no">
                      Lena Jensen
                    </a>
                  </td>
                  <td>Sales and Marketing Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:crystal.andvik@intrafish.com">
                      Crystal Andvik
                    </a>
                  </td>
                  <td>Customer Account Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:marit.aarre@fiskeribladet.no">
                      Marit Aarre
                    </a>
                  </td>
                  <td>Account Manger</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:ben-erik.ness@intrafish.no">
                      Ben-Erik Ness
                    </a>
                  </td>
                  <td>Key Account Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:jorgen.sevold@fiskeribladet.no">
                      Jørgen Sevold
                    </a>
                  </td>
                  <td>Account Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:Finn.Larsen@fiskeribladet.no">
                      Finn Christian Larsen
                    </a>
                  </td>
                  <td>Account Manager</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:erik.dyngeland@intrafish.com">
                      Erik Dyngeland
                    </a>
                  </td>
                  <td>Marketing Production</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:maj.juvik@intrafish.com">
                      Maj Juvik
                    </a>
                  </td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:kari.martinsen@fiskeribladet.no">
                      Kari Martinsen
                    </a>
                  </td>
                  <td>Finance</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:monica.wiig@fiskeribladet.no">
                      Monica Wiig
                    </a>
                  </td>
                  <td>Administration</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:lillian.halland@intrafish.com">
                      Lillian Halland
                    </a>
                  </td>
                  <td>Sales Assistant</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Bodø
            </a>
            <br>
            <p>
              Storgata 27, 3 etg<br>
              Postboks 1164<br>
              NO-8001 Bodø<br>
              Norway
            </p>
            <p>
              Tel: +47 755 44 909
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:bent.jensen@intrafish.com">
                      Bent Jensen
                    </a>
                  </td>
                  <td>Journalist, IntraFish.no</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Oslo
            </a>
            <br>
            <p>
              Chr. Krohgs gate 16<br>
              0186 Oslo<br>
              Norway
            </p>
            <p>
              Tel: +47 22 00 10 12
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:anders.furuset@intrafish.no">
                      Anders Furuset
                    </a>
                  </td>
                  <td>Journalist, IntraFish.no</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:Heidi.leikanger@intrafish.com">
                      Heidi Leikanger
                    </a>
                  </td>
                  <td>Team Leader Global</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              London
            </a>
            <br>
            <p>
              125 Wood Street<br>
              7th Floor<br>
              London<br>
              EC2V 7AN<br>
              United Kingdom
            </p>
            <p>
              Tel: +44 207 6452 335
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:demi.korban@intrafish.com">
                      Demi Korban
                    </a>
                  </td>
                  <td>Business Reporter, IntraFish.com</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:Nina.Unlay@intrafish.com">
                      Nina Unlay
                    </a>
                  </td>
                  <td>Business Reporter, IntraFish.com</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:seumas.grey@intrafish.com">
                      Seumas Grey
                    </a>
                  </td>
                  <td>Advertising Sales Director</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:costa.skotidas@intrafish.com">
                      Costa Skotidas
                    </a>
                  </td>
                  <td>Account Manager, IntraFish.com</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Puerto Varas
            </a>
            <br>
            <p>
              PO BOX 1086 - Puerto Varas – Chile
            </p>
            <p>
              Tel: +56 65 970123
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:Marianne.gaedicke@intrafish.com">
                      Marianne Gaedicke
                    </a>
                  </td>
                  <td>General Manager, IntraFish Media Chile</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Seattle
            </a>
            <br>
            <p>
              3500 188th St. SW Ste 335<br>
              Lynnwood, WA 98037<br>
              United States
            </p>
            <p>
              Tel: +1 206 282 3474
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:drew.cherry@intrafish.com">
                      Drew Cherry
                    </a>
                  </td>
                  <td>Editor-in-Chief, IntraFish.com</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:john.fiorillo@intrafish.com">
                      John Fiorillo
                    </a>
                  </td>
                  <td>Executive Editor</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:john.evans@intrafish.com">
                      John Evans
                    </a>
                  </td>
                  <td>Correspondent, IntraFish.com</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:rachel.sapin@intrafish.com">
                      Rachel Sapin
                    </a>
                  </td>
                  <td>Business Reporter, IntraFish.com</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:courtney.peterson@intrafish.com">
                      Courtney Peterson
                    </a>
                  </td>
                  <td>Advertising Sales Executive</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>

          <div>
            <a
              @click.prevent="$event.target.parentElement.classList.toggle('show')"
              href=""
              class="font-weight-bold">
              Singapore
            </a>
            <br>
            <p>
              20 Upper Circular Road<br>
              The Riverwalk #04-04<br>
              Singapore 058416
            </p>
            <p>
              Tel: +65 3165 0980
            </p>
            <table class="details">
              <tbody>
                <tr>
                  <td>
                    <a href="mailto:rachel.mutter@intrafish.com">
                      Rachel Mutter
                    </a>
                  </td>
                  <td>Editor, IntraFish.com</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:des.tan@intrafish.com">
                      Des Tan
                    </a>
                  </td>
                  <td>Account Manager Circulation Sales</td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:john.dogma@intrafish.com">
                      John Dogma
                    </a>
                  </td>
                  <td>Account Manager</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'contactus-page',
  extends: Page
};
</script>
